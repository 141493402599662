import React, { useEffect, useState } from 'react';
import './variables.css';
import './global.css';
import Seo from './SeoComponent';
import Footer from './FooterNew';
import { Container, Grid, useMediaQuery } from '@mui/material';
//import Mainnavigation from './NavigationNew';
import Mainnavigation from './NavigationHomeNew';
import AdBlock from './AdBlock';
import sanitizeHtml from 'sanitize-html';
import Breadcrumbs from './common/Breadcrumbs';
import DeviceOverviewBanner from './DeviceOverviewBanner';
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import Spinner from './common/Spinner';

const Tab = styled(TabUnstyled)`
    color: #111111;
    font-family: Apercu !important;
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    padding: 24px 16px;
    border: none;
    display: flex;
    justify-content: center;
    background-color: #fff;

    &:hover {
        background-color: #f6f0fc;
        color: #111111;
    }

    &:focus {
        outline-offset: 2px;
    }

    &.${tabUnstyledClasses.selected} {
        background-color: #8223d2;
        color: #fff;
    }

    &.${buttonUnstyledClasses.disabled} {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

const TabsList = styled(TabsListUnstyled)`
    min-width: 320px;
    display: flex;
    align-items: left;
    justify-content: left;
    align-content: space-between;
`;

// import FollowUs from '../templates/FollowUs';

function PhoneComponent({
    // postTitle = '',
    // brandName = 'Apple',
    // adBlockDynamicPrice = '0.0',
    // adBlockPromoText = '',
    // adBlockDescription = '',
    // activeTab = '',
    // subcomponent = <></>,
    // canonicalUrl = '',
    // breadCrumbs = '',
    // bannerImageDesktop = '',
    // bannerImageMobile = '',
    postTitle,
    brandName,
    adBlockDynamicPrice,
    adBlockPromoText,
    adBlockDescription,
    activeTab,
    subcomponent,
    canonicalUrl,
    breadCrumbs,
    bannerImageDesktop,
    bannerImageMobile,
    isSmall,
    endComponent,
}) {
    // postTitle = sanitizeHtml(postTitle.toLowerCase());
    const [defaultTab, setDefaultTab] = useState(0);
    const [pageLoading, setPageLoading] = useState(true);
    postTitle = postTitle;
    brandName = sanitizeHtml(brandName.toLowerCase());
    adBlockDynamicPrice = sanitizeHtml(adBlockDynamicPrice);
    adBlockPromoText = adBlockPromoText;
    adBlockDescription = adBlockDescription;

    // console.log(activeTab);

    // const domainUrl = process.env.GATSBY_DOMAIN_URL || '';
    //const domainUrl = '';
    // const postTitleUrl = postTitle.replaceAll(' ', '-');
    //const postTitleUrl = sanitizeHtml(postTitle.toLowerCase()).replaceAll(' ', '-');
    //const reviewUrl = `${domainUrl}/supported-phones/${brandName}/${postTitleUrl}`;
    //const repairUrl = `${domainUrl}/supported-phones/${brandName}/${postTitleUrl}/screen-repair`;
    //const specsUrl = `${domainUrl}/supported-phones/${brandName}/${postTitleUrl}/specifications`;
    //const articleUrl = `${domainUrl}/supported-phones/${brandName}/${postTitleUrl}/articles`;

    useEffect(() => {
        if (typeof window !== undefined) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const sectionView = urlParams.get('view');
            if (sectionView === 'specs') {
                setDefaultTab(1);
            }
            setPageLoading(false);
        }
    }, []);

    return (
        <>
            <Seo />
            <Mainnavigation />
            <Breadcrumbs breadCrumbs={breadCrumbs} />
            {pageLoading ? (
                <Spinner />
            ) : (
                <>
                    <DeviceOverviewBanner
                        deviceName={postTitle}
                        bannerImageDesktop={bannerImageDesktop}
                        bannerImageMobile={bannerImageMobile}
                    />
                    {activeTab !== 'repair' ? (
                        <TabsUnstyled defaultValue={defaultTab}>
                            <Grid xs={12} md={12} sx={{ borderBottom: '1px solid #D7D7D7' }}>
                                <Grid xs={8} md={8} sx={{ maxWidth: '1400px', margin: '0 auto' }}>
                                    <TabsList sx={{ marginLeft: { xs: '4%', md: '2%' }, fontFamily: 'Apercu' }}>
                                        <Tab>Overview</Tab>
                                        <Tab>Tech Specifications</Tab>
                                    </TabsList>
                                </Grid>
                            </Grid>
                            <main>
                                <Container maxWidth="xl" sx={{ height: '100%', py: 6, maxWidth: '1400px !important' }}>
                                    {/* Left side of content */}
                                    <Grid xs={12} container>
                                        <Grid xs={12} md={8} item>
                                            <Grid xs={12} md={8} sx={{ maxWidth: { xs: 'unset', md: '793px' } }}>
                                                <AdBlock
                                                    modelName={postTitle}
                                                    brandName={brandName}
                                                    adBlockDynamicPrice={adBlockDynamicPrice}
                                                    adBlockPromoText={adBlockPromoText}
                                                    adBlockDescription={adBlockDescription}
                                                    isSecondaryBlock="true"
                                                />
                                            </Grid>

                                            <Grid xs={12} item md={8} sx={{ maxWidth: { xs: 'unset', md: '793px' } }}>
                                                {subcomponent}
                                            </Grid>

                                            <Grid xs={12} md={8} sx={{ mt: 2, maxWidth: { xs: 'unset', md: '793px' } }}>
                                                <AdBlock
                                                    modelName={postTitle}
                                                    brandName={brandName}
                                                    adBlockDynamicPrice={adBlockDynamicPrice}
                                                    adBlockPromoText={adBlockPromoText}
                                                    adBlockDescription={adBlockDescription}
                                                    isSecondaryBlock="true"
                                                    canonicalUrl={canonicalUrl}
                                                />
                                            </Grid>
                                        </Grid>
                                        {/* Right side of content */}
                                        <Grid xs={4} item></Grid>
                                    </Grid>
                                </Container>
                            </main>
                        </TabsUnstyled>
                    ) : (
                        // <Grid xs={12} md={8} container>
                        //     {subcomponent}
                        // </Grid>
                        <main>
                            <Container maxWidth="xl" sx={{ height: '100%', py: 6, maxWidth: '1400px !important' }}>
                                {/* Left side of content */}
                                <Grid xs={12} container>
                                    <Grid xs={12} md={8} item>
                                        <Grid xs={12} md={8} sx={{ maxWidth: { xs: 'unset', md: '793px' } }}>
                                            <AdBlock
                                                modelName={postTitle}
                                                brandName={brandName}
                                                adBlockDynamicPrice={adBlockDynamicPrice}
                                                adBlockPromoText={adBlockPromoText}
                                                adBlockDescription={adBlockDescription}
                                                isSecondaryBlock="true"
                                            />
                                        </Grid>

                                        <Grid xs={12} item md={8} sx={{ maxWidth: { xs: 'unset', md: '793px' } }}>
                                            {subcomponent}
                                        </Grid>

                                        <Grid xs={12} md={8} sx={{ mt: 2, maxWidth: { xs: 'unset', md: '793px' } }}>
                                            <AdBlock
                                                modelName={postTitle}
                                                brandName={brandName}
                                                adBlockDynamicPrice={adBlockDynamicPrice}
                                                adBlockPromoText={adBlockPromoText}
                                                adBlockDescription={adBlockDescription}
                                                isSecondaryBlock="true"
                                                canonicalUrl={canonicalUrl}
                                            />
                                        </Grid>
                                    </Grid>
                                    {/* Right side of content */}
                                    <Grid xs={4} item></Grid>
                                </Grid>
                            </Container>
                        </main>
                    )}
                    {endComponent}
                </>
            )}
            {/* <FollowUs /> */}
            <Footer />
        </>
    );
}

export default PhoneComponent;
