import React from 'react';
import { Container, Grid, Box, Typography, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

function DeviceOverviewBanner({ deviceName, bannerImageDesktop, bannerImageMobile }) {
    const theme = useTheme();
    const isMobile: boolean = useMediaQuery(theme.breakpoints.down('md'));

    const bannerDesktop: JSX.Element = (
        <Box height="360px" sx={{ background: '#FAFAFA' }}>
            <Container sx={{ height: '100%', maxWidth: '1400px !important', px: '0 !important' }}>
                <Grid height="100%" display="flex" flexDirection="column" container>
                    {bannerImageDesktop !== null ? (
                        <BackgroundImage
                            {...convertToBgImage(getImage(bannerImageDesktop))}
                            preserveStackingContext
                            style={{
                                height: '100%',
                                width: '100%',
                                zIndex: 1,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                            loading="eager"
                        >
                            <Typography
                                variant="h1"
                                align="center"
                                fontSize="3rem"
                                fontWeight="bold"
                                lineHeight="60px"
                                mt={10}
                            >
                                {deviceName} Insurance
                            </Typography>
                        </BackgroundImage>
                    ) : (
                        <Typography
                            variant="h1"
                            align="center"
                            fontSize="3rem"
                            fontWeight="bold"
                            lineHeight="60px"
                            mt={10}
                        >
                            {deviceName} Insurance
                        </Typography>
                    )}
                </Grid>
            </Container>
        </Box>
    );
    const bannerMobile: JSX.Element = (
        <Box height="360px" sx={{ background: '#FAFAFA' }}>
            <Grid height="100%" display="flex" flexDirection="column" container>
                {bannerImageMobile !== null ? (
                    <BackgroundImage
                        {...convertToBgImage(getImage(bannerImageMobile))}
                        preserveStackingContext
                        style={{
                            height: '100%',
                            width: '100%',
                            zIndex: 1,
                            backgroundSize: 'contain',
                            backgroundPosition: 'center bottom',
                        }}
                        loading="eager"
                    >
                        <Typography
                            variant="h1"
                            align="center"
                            fontSize="2.25rem"
                            fontWeight="bold"
                            lineHeight="44.64px"
                            px={1}
                            mt={5}
                        >
                            {deviceName} Insurance
                        </Typography>
                    </BackgroundImage>
                ) : (
                    <Typography
                        variant="h1"
                        align="center"
                        fontSize="2.25rem"
                        fontWeight="bold"
                        lineHeight="44.64px"
                        px={1}
                        mt={5}
                    >
                        {deviceName} Insurance
                    </Typography>
                )}
            </Grid>
        </Box>
    );
    return <Box>{isMobile ? bannerMobile : bannerDesktop}</Box>;
}

export default DeviceOverviewBanner;
