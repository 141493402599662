import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Seo from '../components/SeoComponent';
import PhoneComponent from '../components/PhoneComponent';
import MiniAd from '../templates/MiniAdTemplate';
import {
    Box,
    Container,
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    useMediaQuery,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme, styled } from '@mui/material/styles';
import { BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import screenIcon from '../images/icon-sm-screen.png';
import lensIcon from '../images/icon-sm-lens.png';
import chipIcon from '../images/icon-sm-chip.png';
import storageIcon from '../images/icon-sm-storage.png';
import colorIcon from '../images/icon-sm-colors.png';
import batteryIcon from '../images/icon-sm-battery.png';
import sanitizeHtml from 'sanitize-html';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { Divider } from '@mui/material';

const TabPanel = styled(TabPanelUnstyled)`
    width: 100%;
`;

const StyledGrid = styled(Grid)({
    marginTop: '1em',
    marginLeft: '0',
    width: '100%',
});

const RoundedGrid = styled(Grid)({
    marginTop: '1em',
});

const StyledAccordion = styled(Accordion)({
    marginBottom: '0px',
    marginTop: '0px',
    borderRadius: '8px !important',
});

const StyledDetailBox = styled(Grid)({
    xs: 12,
    display: 'flex',

    '& > .MuiGrid-item': {
        paddingLeft: '32px',
        paddingTop: '10px',
    },
});

const renderingOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <Box component="p">{children}</Box>,
        [BLOCKS.HEADING_3]: (node, children) => <Box component="p">{children}</Box>,
        [BLOCKS.HEADING_2]: (node, children) => (
            <Box component="h2" sx={{ fontSize: '24px' }}>
                {children}
            </Box>
        ),
        [BLOCKS.UL_LIST]: (node, children) => <Box component="div">{children}</Box>,
        [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
        [BLOCKS.LIST_ITEM]: (node, children) => (
            <Box component="div" sx={{ boxShadow: '0px 1px 0px #D7D7D7', padding: '12px 0', color: '#757575' }}>
                {children}
            </Box>
        ),
    },
    renderText: (text) => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
};

const whatsInBoxRenderingOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => (
            <Box component="p" sx={{ pt: 1 }}>
                {children}
            </Box>
        ),
        [BLOCKS.HEADING_3]: (node, children) => (
            <Box
                component="h3"
                sx={{
                    fontFamily: 'Apercu',
                    fontSize: '24px',
                    textAlign: 'left',
                    fontWeight: 'bold',
                    margin: '10px 0 0px',
                }}
            >
                {children}
            </Box>
        ),
        [BLOCKS.UL_LIST]: (node, children) => <Box component="div">{children}</Box>,
        [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
        [BLOCKS.LIST_ITEM]: (node, children) => (
            <Box
                component="div"
                sx={{
                    boxShadow: '0px 1px 0px #D7D7D7',
                    padding: '12px 0',
                    color: '#757575',
                    fontSize: '16px',
                    fontWeight: '400',
                }}
            >
                {children}
            </Box>
        ),
    },
    renderText: (text) => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
};
const specsRenderingOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <Box component="p">{children}</Box>,
        [BLOCKS.UL_LIST]: (node, children) => <Box component="p">{children}</Box>,
        [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
        [BLOCKS.LIST_ITEM]: (node, children) => (
            <Box component="p" sx={{ color: '#757575', fontSize: '16px', fontWeight: '400' }}>
                {children}
            </Box>
        ),
    },
    renderText: (text) => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
};
const accordionRenderingOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => (
            <Box component="p">
                {children}
                <br />
            </Box>
        ),
        [BLOCKS.HEADING_3]: (node, children) => <Box component="p">{children}</Box>,
        [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
        [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
        [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
    },
    renderText: (text) => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
};

const DescriptionRow = ({ title, description }) => (
    <Grid container xs={12} mt={1}>
        <Grid xs={12} sx={{ boxShadow: '0px 1px 0px #D7D7D7', padding: '12px 0px' }}>
            <Typography component="div" width={1} sx={{ lineHeight: '1.1', float: 'left' }}>
                <Typography component="div" sx={{ fontWeight: '600', float: 'left', mr: 2, width: 'auto' }}>
                    {' '}
                    {title}{' '}
                </Typography>
                <Typography component="div" sx={{ color: '#757575', display: 'table-cell' }}>
                    {description}
                </Typography>
            </Typography>
        </Grid>
    </Grid>
);

const Specs = ({ alt, logo, text }) => (
    <Grid height="100%" container width={1} sx={{ boxShadow: '0px 1px 0px #D7D7D7', padding: '12px 0' }}>
        <Grid xs={1}>
            <img src={logo} alt={alt} height="20" width="auto" />
        </Grid>
        <Grid xs={11} pl={0}>
            <Typography variant="span" sx={{ fontSize: '16px', lineHeight: '16px' }}>
                {text}
            </Typography>
        </Grid>
    </Grid>
);

const CenteredImageGrid = ({ url, alt, credits, imgHeight }) => (
    <Grid container spacing={0} sx={{ mt: 2 }}>
        <Grid
            xs={12}
            display="flex"
            justifyContent="center"
            alighnItems="center"
            sx={{ height: { xs: '15rem', md: '32rem' } }}
        >
            <GatsbyImage image={getImage(url)} objectFit="contain" alt={alt || 'review'} />
        </Grid>

        <Grid xs={12} sx={{ mt: '10px', fontSize: '12px', color: '#757575' }}>
            <Typography component="div" sx={{ fontSize: '12px' }}>
                {credits}
            </Typography>
        </Grid>
    </Grid>
);

const ReviewAccordion = ({ title, nodeRichText }) => (
    <StyledAccordion sx={{ boxShadow: '0', border: '1px solid #C4C4C4', mb: 2, borderRadius: '8px' }}>
        <AccordionSummary aria-controls="panel1d-content" expandIcon={<ExpandMoreIcon />}>
            <Typography
                fontWeight="600"
                sx={{
                    fontSize: '16px',
                    fontWeight: '600',
                    lineHeight: '19px',
                    color: '#671CA6',
                }}
            >
                {title}
            </Typography>
        </AccordionSummary>
        <AccordionDetails>{renderRichText(nodeRichText, accordionRenderingOptions)}</AccordionDetails>
    </StyledAccordion>
);

const SpecSectionTitle = ({ title }) => (
    <Typography component="div" sx={{ fontWeight: '700', fontSize: '24px', lineHeight: '29px', mt: 4 }} align="left">
        {title}
    </Typography>
);

class ReviewTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPageSection: 0,
        };
    }

    render() {
        //const isMobile = sanitizeHtml(this.props.isMobile);
        const isSmall = sanitizeHtml(this.props.isSmall);
        const post = get(this.props, 'data.reviewData');
        const metaTags = get(this.props, 'data.metaTags');
        const breadCrumbs = get(this.props, 'pageContext.breadCrumbs');
        const bannerImageDesktop = get(this.props, 'pageContext.bannerImageDesktop');
        const bannerImageMobile = get(this.props, 'pageContext.bannerImageMobile');

        const reviewDescription = post && post.reviewDescription ? sanitizeHtml(post.reviewDescription) : '';
        // const reviewMediaUrl = post && post.reviewVideo ? sanitizeHtml(post.reviewVideo) : '';
        const reviewMediaTitle = post && post.reviewVideo ? sanitizeHtml(post.reviewVideo.title) : '';
        const imageCredits = post && post.imageCredits ? sanitizeHtml(post.imageCredits) : '';
        const reviewPrice = post && post.reviewPrice ? sanitizeHtml(post.reviewPrice) : '';
        const whatsNew = post && post.reviewWhatsNew ? sanitizeHtml(post.reviewWhatsNew) : '';
        const releaseDate = post && post.reviewReleaseDate ? sanitizeHtml(post.reviewReleaseDate) : '';
        const rate = post && post.reviewAsurionRate ? post.reviewAsurionRate : '';
        // const pros = post && post.reviewLikes ? sanitizeHtml(post.reviewLikes) : '';
        // const cons = post && post.reviewDislikes ? sanitizeHtml(post.reviewDislikes) : '';
        // const bottomLines = post && post.reviewBottomLines ? sanitizeHtml(post.reviewBottomLines) : '';

        const pros = post && post.reviewLikes ? post.reviewLikes : '';
        const cons = post && post.reviewDislikes ? post.reviewDislikes : '';
        const bottomLines = post && post.reviewBottomLines ? post.reviewBottomLines : '';

        //const reviewImagesUrl = post && post.reviewImages ? sanitizeHtml(post.reviewImages.file.url) : '';
        const reviewImagesFile = post && post.reviewImages ? sanitizeHtml(post.reviewImages.file.fileName) : '';

        const reviewDeviceSpecTitle =
            post && post.reviewDeviceSpecTitle ? sanitizeHtml(post.reviewDeviceSpecTitle) : '';
        const deviceSpecsScreen = post && post.reviewDeviceSpecTitle ? sanitizeHtml(post.reviewDeviceSpec.screen) : '';
        const deviceSpecsPower = post && post.reviewDeviceSpecTitle ? sanitizeHtml(post.reviewDeviceSpec.power) : '';
        const deviceSpecsRam = post && post.reviewDeviceSpecTitle ? sanitizeHtml(post.reviewDeviceSpec.ram) : '';
        const deviceSpecsBattery =
            post && post.reviewDeviceSpecTitle ? sanitizeHtml(post.reviewDeviceSpec.battery) : '';
        const deviceSpecsDisk = post && post.reviewDeviceSpecTitle ? sanitizeHtml(post.reviewDeviceSpec.disk) : '';
        const deviceSpecsColor = post && post.reviewDeviceSpecTitle ? sanitizeHtml(post.reviewDeviceSpec.color) : '';

        //const whatsInTheBoxImageUrl = post && post.whatsInTheBoxImage ? post.whatsInTheBoxImage.file.url : '';
        const whatsInTheBoxImageUrl = post && post.whatsInTheBoxImage ? post.whatsInTheBoxImage : '';

        const whatsInTheBoxImageFile = post && post.whatsInTheBoxImage ? post.whatsInTheBoxImage.file.fileName : '';
        const reviewWhatsInTheBoxBelowBanner =
            post && post.reviewWhatsInTheBoxBelowBanner ? post.reviewWhatsInTheBoxBelowBanner : '';

        const designAndDisplay = post && post.designAndDisplay ? post.designAndDisplay : '';
        const camera = post && post.camera ? post.camera : '';
        const battery = post && post.battery ? post.battery : '';
        const affordability = post && post.affordability ? post.affordability : '';

        const postTitle = post && post.title ? post.title : '';
        const postBrandName = post && post.brandName ? post.brandName : '';
        const postPrice = post && post.adBlockDynamicPrice ? post.adBlockDynamicPrice : '';
        const postPromoText = post && post.adBlockPromoText ? post.adBlockPromoText : '';
        const postDescription = post && post.adBlockDescription ? post.adBlockDescription : '';
        const miniAdText = post && post.miniAdText ? post.miniAdText : '';
        const miniAdArticleLink = post && post.miniAdArticleLink ? post.miniAdArticleLink : '';
        const miniAdSpecsText = post && post.miniAdSpecsText ? post.miniAdSpecsText : '';
        const miniAdSpecsArticleLink = post && post.miniAdSpecsArticleLink ? post.miniAdSpecsArticleLink : '';
        //console.log(miniAdArticleLink);
        //console.log(post);

        const metaTitle = postTitle + ' | Reviews | Asurion Mobile+';
        const metaDescription = metaTags && metaTags.description ? metaTags.description : '';
        const ogImage = metaTags && metaTags.ogImage ? metaTags.ogImage.file.url : '';

        // const domainUrl = process.env.GATSBY_DOMAIN_URL || '';
        const domainUrl = '';
        const canonicalUrl =
            this.props && this.props.pageContext && this.props.pageContext.canonicalPath
                ? domainUrl + this.props.pageContext.canonicalPath
                : domainUrl + this.props.path;

        // console.log('canonical', canonicalUrl);

        //specs data

        const specs = get(this.props, 'data.specsData');

        const networkSectionTitle = specs && specs.networkSectionTitle ? specs.networkSectionTitle : '';
        const networkTechnology = specs && specs.networkTechnology ? specs.networkTechnology : '';
        const networkSpeed = specs && specs.networkSpeed ? specs.networkSpeed : '';
        const launchSectionTitle = specs && specs.launchSectionTitle ? specs.launchSectionTitle : '';
        const launchAnnounced = specs && specs.launchAnnounced ? specs.launchAnnounced : '';
        const launchStatus = specs && specs.launchStatus ? specs.launchStatus : '';
        const bodySectionTitle = specs && specs.bodySectionTitle ? specs.bodySectionTitle : '';
        const bodyDimensions = specs && specs.bodyDimensions ? specs.bodyDimensions : '';
        const bodyWeight = specs && specs.bodyWeight ? specs.bodyWeight : '';
        const bodyBuild = specs && specs.bodyBuild ? specs.bodyBuild : '';
        const bodySim = specs && specs.bodySim ? specs.bodySim : '';
        const displaySectionTitle = specs && specs.displaySectionTitle ? specs.displaySectionTitle : '';
        const displayType = specs && specs.displayType ? specs.displayType : '';
        const displaySize = specs && specs.displaySize ? specs.displaySize : '';
        const displayResolution = specs && specs.displayResolution ? specs.displayResolution : '';
        const displayProtection = specs && specs.displayProtection ? specs.displayProtection : '';
        const platformSectionTitle = specs && specs.platformSectionTitle ? specs.platformSectionTitle : '';
        const platformOs = specs && specs.platformOs ? specs.platformOs : '';
        const platformCpu = specs && specs.platformCpu ? specs.platformCpu : '';
        const platformGpu = specs && specs.platformGpu ? specs.platformGpu : '';
        const platformChipset = specs && specs.platformChipset ? specs.platformChipset : '';
        const memorySectionTitle = specs && specs.memorySectionTitle ? specs.memorySectionTitle : '';
        const memoryCardSlot = specs && specs.memoryCardSlot ? specs.memoryCardSlot : '';

        const memoryInternal = specs && specs.memoryInternal ? specs.memoryInternal : '';
        const mainCameraSectionTitle = specs && specs.mainCameraSectionTitle ? specs.mainCameraSectionTitle : '';
        const mainCameraQuad = specs && specs.mainCameraQuad ? specs.mainCameraQuad : '';
        const mainCameraTriple = specs && specs.mainCameraTriple ? specs.mainCameraTriple : '';
        const mainCameraDual = specs && specs.mainCameraDual ? specs.mainCameraDual : '';
        const mainCameraSingle = specs && specs.mainCameraSingle ? specs.mainCameraSingle : '';
        const mainCameraFeatures = specs && specs.mainCameraFeatures ? specs.mainCameraFeatures : '';
        const mainCameraVideo = specs && specs.mainCameraVideo ? specs.mainCameraVideo : '';
        const selfieCameraSectionTitle = specs && specs.selfieCameraSectionTitle ? specs.selfieCameraSectionTitle : '';

        const selfieCameraDual = specs && specs.selfieCameraDual ? specs.selfieCameraDual : '';
        const selfieCameraSingle = specs && specs.selfieCameraSingle ? specs.selfieCameraSingle : '';
        const selfieCameraVideo = specs && specs.selfieCameraVideo ? specs.selfieCameraVideo : '';
        const soundSectionTitle = specs && specs.soundSectionTitle ? specs.soundSectionTitle : '';
        const sound35mmJack = specs && specs.sound35mmJack ? specs.sound35mmJack : '';
        const soundLoudspeaker = specs && specs.soundLoudspeaker ? specs.soundLoudspeaker : '';
        const featuresSectionTitle = specs && specs.featuresSectionTitle ? specs.featuresSectionTitle : '';
        const featuresSensors = specs && specs.featuresSensors ? specs.featuresSensors : '';
        const batterySectionTitle = specs && specs.batterySectionTitle ? specs.batterySectionTitle : '';
        const batteryType = specs && specs.batteryType ? specs.batteryType : '';
        const batteryStandBy = specs && specs.batteryStandBy ? specs.batteryStandBy : '';
        const batteryCharging = specs && specs.batteryCharging ? specs.batteryCharging : '';
        const batteryMusicPlay = specs && specs.batteryMusicPlay ? specs.batteryMusicPlay : '';

        return (
            <PhoneComponent
                postTitle={postTitle}
                brandName={postBrandName}
                adBlockDynamicPrice={postPrice}
                adBlockPromoText={postPromoText}
                adBlockDescription={postDescription}
                activeTab={'review'}
                canonicalUrl={canonicalUrl}
                breadCrumbs={breadCrumbs}
                bannerImageDesktop={bannerImageDesktop}
                bannerImageMobile={bannerImageMobile}
                isSmall={isSmall}
                subcomponent={
                    <>
                        <Seo
                            title={metaTitle}
                            description={metaDescription}
                            ogImage={ogImage}
                            image={ogImage}
                            canonical={canonicalUrl}
                        />
                        <Grid sx={{ mt: 2, mb: 2, ml: 'auto', mr: 'auto', color: '#111111' }} width={{ md: '100%' }}>
                            <TabPanel value={0}>
                                <Container
                                    maxWidth="100%"
                                    fixed
                                    className="ltArticleContainer"
                                    sx={{ padding: '0px!important' }}
                                >
                                    <Grid
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="left"
                                        sx={{ mb: 2, mt: 6 }}
                                    >
                                        <Typography
                                            sx={{ fontSize: '32px', fontWeight: 'bold', lineHeight: '39.68px', mt: 4 }}
                                        >
                                            {postTitle} Insurance
                                        </Typography>
                                        <Typography
                                            component="div"
                                            sx={{ fontSize: '18px', lineHeight: '22.32px', mt: 1 }}
                                        >
                                            {reviewDescription}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        container
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <CenteredImageGrid
                                            url={post.reviewVideo}
                                            alt={reviewMediaTitle}
                                            credits={imageCredits}
                                            imgHeight={isSmall ? '15rem' : '32rem'}
                                        />
                                        <Divider sx={{ border: '2px solid #D7D7D7', width: '100%', mt: 2 }}></Divider>
                                        <StyledGrid container>
                                            <DescriptionRow title="Release" description={releaseDate}></DescriptionRow>
                                            <DescriptionRow title="Price" description={reviewPrice}></DescriptionRow>
                                            <DescriptionRow title="What's New" description={whatsNew}></DescriptionRow>
                                            {rate && (
                                                <DescriptionRow title="Rating" description={rate}></DescriptionRow>
                                            )}
                                        </StyledGrid>

                                        <Box
                                            display="grid"
                                            gridTemplateColumns="repeat(2, 1fr)"
                                            gap={2}
                                            width={1}
                                            mt={2}
                                        >
                                            <RoundedGrid
                                                sx={{ marginTop: '0' }}
                                                gridColumn={{ xs: 'span 2', md: 'span 1' }}
                                            >
                                                {renderRichText(pros, renderingOptions)}
                                            </RoundedGrid>
                                            <RoundedGrid
                                                sx={{ marginTop: '0' }}
                                                gridColumn={{ xs: 'span 2', md: 'span 1' }}
                                            >
                                                {renderRichText(cons, renderingOptions)}
                                            </RoundedGrid>
                                        </Box>

                                        {bottomLines && (
                                            <Box
                                                sx={{
                                                    mt: 0,
                                                    boxShadow: '0px 1px 0px #D7D7D7',
                                                    padding: '12px 0',
                                                    width: '100%',
                                                    fontSize: '18px',
                                                }}
                                            >
                                                <RoundedGrid item xs={12} mb={1}>
                                                    {renderRichText(bottomLines, renderingOptions)}
                                                </RoundedGrid>
                                            </Box>
                                        )}

                                        <CenteredImageGrid
                                            url={post.reviewImages}
                                            alt={reviewImagesFile}
                                            credits={imageCredits}
                                        />

                                        <StyledGrid container spacing={1} display="flex" flexDirection="row">
                                            <Grid
                                                item
                                                display="flex"
                                                justifyContent="left"
                                                xs={12}
                                                sx={{ mb: '0px', paddingLeft: '0!important' }}
                                            >
                                                <Typography sx={{ fontSize: '24px', fontWeight: 'bold' }}>
                                                    {reviewDeviceSpecTitle}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                container
                                                spacing={4}
                                                sx={{ color: '#757575', paddingTop: '20px!important' }}
                                            >
                                                <StyledDetailBox
                                                    xs={12}
                                                    direction={{
                                                        xs: 'column',
                                                        sm: 'column',
                                                        md: 'row',
                                                        lg: 'row',
                                                        xl: 'row',
                                                    }}
                                                >
                                                    <Grid item xs={12} md={6} sx={{ paddingTop: '10px!important' }}>
                                                        <Specs
                                                            alt="specs screen"
                                                            logo={screenIcon}
                                                            text={deviceSpecsScreen}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} sx={{ paddingTop: '10px!important' }}>
                                                        <Specs
                                                            alt="specs power"
                                                            logo={lensIcon}
                                                            text={deviceSpecsPower}
                                                        />
                                                    </Grid>
                                                </StyledDetailBox>
                                                <StyledDetailBox
                                                    xs={12}
                                                    direction={{
                                                        xs: 'column',
                                                        sm: 'column',
                                                        md: 'row',
                                                        lg: 'row',
                                                        xl: 'row',
                                                    }}
                                                >
                                                    <Grid item xs={12} md={6}>
                                                        <Specs alt="specs ram" logo={chipIcon} text={deviceSpecsRam} />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Specs
                                                            alt="specs battery"
                                                            logo={batteryIcon}
                                                            text={deviceSpecsBattery}
                                                        />
                                                    </Grid>
                                                </StyledDetailBox>
                                                <StyledDetailBox
                                                    xs={12}
                                                    direction={{
                                                        xs: 'column',
                                                        sm: 'column',
                                                        md: 'row',
                                                        lg: 'row',
                                                        xl: 'row',
                                                    }}
                                                >
                                                    <Grid item xs={12} md={6}>
                                                        <Specs
                                                            alt="specs disk"
                                                            logo={storageIcon}
                                                            text={deviceSpecsDisk}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Specs
                                                            alt="specs color"
                                                            logo={colorIcon}
                                                            text={deviceSpecsColor}
                                                        />
                                                    </Grid>
                                                </StyledDetailBox>
                                            </Grid>
                                        </StyledGrid>

                                        <CenteredImageGrid
                                            url={whatsInTheBoxImageUrl}
                                            alt={whatsInTheBoxImageFile}
                                            credits={imageCredits}
                                        />

                                        <StyledGrid>
                                            {reviewWhatsInTheBoxBelowBanner &&
                                                renderRichText(
                                                    reviewWhatsInTheBoxBelowBanner,
                                                    whatsInBoxRenderingOptions
                                                )}
                                        </StyledGrid>

                                        <Grid item xs={12}>
                                            <MiniAd link={miniAdArticleLink}>
                                                <Typography sx={{ fontSize: '24px', fontWeight: '700' }}>
                                                    {miniAdText}{' '}
                                                </Typography>
                                            </MiniAd>
                                        </Grid>

                                        <Grid container>
                                            <Grid item xs={{ marginTop: '0px', marginBottom: '0px' }}>
                                                <ReviewAccordion
                                                    title="Design and Display"
                                                    nodeRichText={designAndDisplay}
                                                ></ReviewAccordion>
                                            </Grid>
                                            <Grid item xs={{ marginTop: '0px', marginBottom: '0px' }}>
                                                <ReviewAccordion title="Camera" nodeRichText={camera}></ReviewAccordion>
                                            </Grid>
                                            <Grid item xs={{ marginTop: '0px', marginBottom: '0px' }}>
                                                <ReviewAccordion
                                                    title="Battery"
                                                    nodeRichText={battery}
                                                ></ReviewAccordion>
                                            </Grid>
                                            <Grid item xs={{ marginTop: '0px', marginBottom: '0px' }}>
                                                <ReviewAccordion
                                                    title="Value for Money"
                                                    nodeRichText={affordability}
                                                ></ReviewAccordion>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </TabPanel>
                            <TabPanel value={1}>
                                <Container
                                    maxWidth="100%"
                                    fixed
                                    className="ltArticleContainer"
                                    sx={{ padding: '0px!important' }}
                                >
                                    <Divider
                                        sx={{ border: '2px solid #D7D7D7', width: '100%', mt: 5, mb: 2 }}
                                    ></Divider>
                                    <SpecSectionTitle title={networkSectionTitle}></SpecSectionTitle>
                                    <StyledGrid container>
                                        <DescriptionRow
                                            title="Technology"
                                            description={networkTechnology}
                                        ></DescriptionRow>
                                        <DescriptionRow title="Speed" description={networkSpeed}></DescriptionRow>
                                    </StyledGrid>
                                    <SpecSectionTitle title={launchSectionTitle}></SpecSectionTitle>
                                    <StyledGrid container>
                                        <DescriptionRow
                                            title="Announced"
                                            description={launchAnnounced}
                                        ></DescriptionRow>
                                        <DescriptionRow title="Status" description={launchStatus}></DescriptionRow>
                                    </StyledGrid>
                                    <SpecSectionTitle title={bodySectionTitle}></SpecSectionTitle>
                                    <StyledGrid container>
                                        <DescriptionRow
                                            title="Dimensions"
                                            description={bodyDimensions}
                                        ></DescriptionRow>
                                        <DescriptionRow title="Weight" description={bodyWeight}></DescriptionRow>
                                        <DescriptionRow title="Build" description={bodyBuild}></DescriptionRow>
                                        {bodySim && (
                                            <DescriptionRow
                                                title="SIM"
                                                description={renderRichText(bodySim, specsRenderingOptions)}
                                            ></DescriptionRow>
                                        )}
                                    </StyledGrid>
                                    <SpecSectionTitle title={displaySectionTitle}></SpecSectionTitle>
                                    <StyledGrid container>
                                        <DescriptionRow title="Type" description={displayType}></DescriptionRow>
                                        <DescriptionRow title="Size" description={displaySize}></DescriptionRow>
                                        <DescriptionRow
                                            title="Resolution"
                                            description={displayResolution}
                                        ></DescriptionRow>
                                        {displayProtection && (
                                            <DescriptionRow
                                                title="Protection"
                                                description={renderRichText(displayProtection, specsRenderingOptions)}
                                            ></DescriptionRow>
                                        )}
                                    </StyledGrid>
                                    <MiniAd sx={{ width: 1 }} link={miniAdSpecsArticleLink}>
                                        <Typography sx={{ fontSize: '24px', fontWeight: '700' }}>
                                            {miniAdSpecsText}{' '}
                                        </Typography>
                                    </MiniAd>
                                    <SpecSectionTitle title={platformSectionTitle}></SpecSectionTitle>
                                    <StyledGrid container>
                                        <DescriptionRow title="OS" description={platformOs}></DescriptionRow>
                                        <DescriptionRow title="Chipset" description={platformChipset}></DescriptionRow>
                                        <DescriptionRow title="CPU" description={platformCpu}></DescriptionRow>
                                        <DescriptionRow title="GPU" description={platformGpu}></DescriptionRow>
                                    </StyledGrid>
                                    <SpecSectionTitle title={memorySectionTitle}></SpecSectionTitle>
                                    <StyledGrid container>
                                        <DescriptionRow title="Card Slot" description={memoryCardSlot}></DescriptionRow>
                                        <DescriptionRow title="Internal" description={memoryInternal}></DescriptionRow>
                                    </StyledGrid>
                                    <SpecSectionTitle title={mainCameraSectionTitle}></SpecSectionTitle>
                                    <StyledGrid container>
                                        {mainCameraQuad && (
                                            <DescriptionRow
                                                title="Quad"
                                                description={renderRichText(mainCameraQuad, specsRenderingOptions)}
                                            ></DescriptionRow>
                                        )}
                                        {mainCameraTriple && (
                                            <DescriptionRow
                                                title="Triple"
                                                description={renderRichText(mainCameraTriple, specsRenderingOptions)}
                                            ></DescriptionRow>
                                        )}
                                        {mainCameraDual && (
                                            <DescriptionRow
                                                title="Dual"
                                                description={renderRichText(mainCameraDual, specsRenderingOptions)}
                                            ></DescriptionRow>
                                        )}
                                        {mainCameraSingle && (
                                            <DescriptionRow
                                                title="Single"
                                                description={mainCameraSingle}
                                            ></DescriptionRow>
                                        )}
                                        <DescriptionRow title="Video" description={mainCameraVideo}></DescriptionRow>
                                        <DescriptionRow
                                            title="Features"
                                            description={mainCameraFeatures}
                                        ></DescriptionRow>
                                    </StyledGrid>
                                    <SpecSectionTitle title={selfieCameraSectionTitle}></SpecSectionTitle>
                                    <StyledGrid container>
                                        {selfieCameraDual && (
                                            <DescriptionRow
                                                title="Dual"
                                                description={renderRichText(selfieCameraDual, specsRenderingOptions)}
                                            ></DescriptionRow>
                                        )}
                                        {selfieCameraSingle && (
                                            <DescriptionRow
                                                title="Single"
                                                description={selfieCameraSingle}
                                            ></DescriptionRow>
                                        )}
                                        <DescriptionRow title="Video" description={selfieCameraVideo}></DescriptionRow>
                                    </StyledGrid>
                                    <SpecSectionTitle title={soundSectionTitle}></SpecSectionTitle>
                                    <StyledGrid container>
                                        <DescriptionRow
                                            title="Loudspeaker"
                                            description={soundLoudspeaker}
                                        ></DescriptionRow>
                                        <DescriptionRow title="3.5mm Jack" description={sound35mmJack}></DescriptionRow>
                                    </StyledGrid>
                                    <SpecSectionTitle title={featuresSectionTitle}></SpecSectionTitle>
                                    <StyledGrid container>
                                        {featuresSensors && (
                                            <DescriptionRow
                                                title="Features & Sensors"
                                                description={renderRichText(featuresSensors, specsRenderingOptions)}
                                            ></DescriptionRow>
                                        )}
                                    </StyledGrid>
                                    <SpecSectionTitle title={batterySectionTitle}></SpecSectionTitle>
                                    <StyledGrid container>
                                        <DescriptionRow title="Type" description={batteryType}></DescriptionRow>
                                        {batteryCharging && (
                                            <DescriptionRow
                                                title="Charging"
                                                description={renderRichText(batteryCharging, specsRenderingOptions)}
                                            ></DescriptionRow>
                                        )}
                                        {batteryStandBy && (
                                            <DescriptionRow
                                                title="Stand-By"
                                                description={batteryStandBy}
                                            ></DescriptionRow>
                                        )}
                                        {batteryMusicPlay && (
                                            <DescriptionRow
                                                title="Music Play"
                                                description={batteryMusicPlay}
                                            ></DescriptionRow>
                                        )}
                                    </StyledGrid>
                                </Container>
                            </TabPanel>
                        </Grid>
                    </>
                }
            />
        );
    }
}

export default function ReviewMainTemplate(props) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isSmall = useMediaQuery(theme.breakpoints.down('lg'));

    // console.log('isMObile', isMobile);
    return <ReviewTemplate {...props} theme={theme} isMobile={isMobile} isSmall={isSmall} />;
}

export const reviewPageQuery = graphql`
    query reviewQuery($title: String, $metaTagTitle: String) {
        reviewData: contentfulDeviceDetailPage(title: { eq: $title }) {
            brand
            brandName
            title
            reviewPrice
            reviewDescription
            adBlockDynamicPrice
            adBlockPromoText {
                raw
            }
            adBlockDescription {
                raw
            }
            camera {
                raw
            }
            battery {
                raw
            }
            designAndDisplay {
                raw
            }
            affordability {
                raw
            }
            reviewVideo {
                title
                file {
                    fileName
                    url
                }
                gatsbyImageData(layout: CONSTRAINED, resizingBehavior: SCALE, height: 528)
            }
            imageCredits
            reviewReleaseDate
            reviewWhatsNew
            reviewLikes {
                raw
            }
            reviewDislikes {
                raw
            }
            reviewBottomLines {
                raw
            }
            reviewImages {
                title
                file {
                    fileName
                    url
                }
                gatsbyImageData(layout: CONSTRAINED, resizingBehavior: SCALE, height: 528)
            }
            reviewDeviceSpecTitle
            reviewDeviceSpec {
                title
                screen
                battery
                power
                disk
                ram
                color
            }
            reviewWhatsInTheBox {
                raw
            }
            reviewWhatsInTheBoxBelowBanner {
                raw
            }
            whatsInTheBoxImage {
                title
                file {
                    fileName
                    url
                }
                gatsbyImageData(layout: CONSTRAINED, resizingBehavior: SCALE, height: 528)
            }
            miniAdText
            miniAdArticleLink
            miniAdSpecsText
            miniAdSpecsArticleLink
        }
        metaTags: contentfulMetaTags(title: { eq: $metaTagTitle }) {
            title
            robots
            canonical
            description
            ogImage {
                file {
                    url
                    fileName
                }
            }
        }
        specsData: contentfulDeviceSpecs(deviceTitle: { eq: $title }) {
            deviceTitle
            networkSectionTitle
            networkTechnology
            networkSpeed
            launchSectionTitle
            launchAnnounced
            launchStatus
            bodySectionTitle
            bodyDimensions
            bodyWeight
            bodyBuild
            bodySim {
                raw
            }
            displaySectionTitle
            displayType
            displaySize
            displayResolution
            displayProtection {
                raw
            }
            platformSectionTitle
            platformOs
            platformCpu
            platformGpu
            platformChipset
            memorySectionTitle
            memoryCardSlot
            memoryInternal
            mainCameraSectionTitle
            mainCameraQuad {
                raw
            }
            mainCameraDual {
                raw
            }
            mainCameraTriple {
                raw
            }
            mainCameraSingle
            mainCameraFeatures
            mainCameraVideo
            selfieCameraSectionTitle
            selfieCameraDual {
                raw
            }
            selfieCameraSingle
            selfieCameraVideo
            soundSectionTitle
            sound35mmJack
            soundLoudspeaker
            featuresSectionTitle
            featuresSensors {
                raw
            }
            batterySectionTitle
            batteryType
            batteryStandBy
            batteryCharging {
                raw
            }
            batteryMusicPlay
            comms {
                title
                wlan
                bluetooth
                gps
                nfc
                radio
                usb
            }
            misc {
                title
                colors
                models
                sarEu
            }
        }
    }
`;
