import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, Link, useMediaQuery } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { truncateSync } from 'fs';

export interface breadCrumb {
    name: string;
    slug: string;
}

function Breadcrumbs({ breadCrumbs }) {
    const [pageLoading, setPageLoading] = useState<boolean>(true);
    const StyledLink = styled(Link)({
        textDecoration: 'none !important',
    });
    const StyledTypography = styled(Typography)({
        color: 'text.disabled',
        '&:hover': {
            cursor: 'pointer',
        },
    });

    const breadCrumbList: breadCrumb[] = breadCrumbs;
    const breadCrumbListLength: number = breadCrumbs.length;
    const theme = useTheme();
    const isMobile: boolean = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        setPageLoading(false);
    }, []);

    return (
        <Box sx={{ background: '#FFFFFF' }}>
            <Container sx={{ maxWidth: '1400px !important' }}>
                {!pageLoading &&
                    (isMobile ? (
                        <StyledLink
                            href={breadCrumbList[breadCrumbListLength - 2].slug}
                            color="text.primary"
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            py={2}
                        >
                            <KeyboardBackspaceIcon sx={{ mr: 2 }} />
                            {breadCrumbList[breadCrumbListLength - 2].name}
                        </StyledLink>
                    ) : (
                        <Box height="52px" display="flex" flexDirection="row" alignItems="center" p={1}>
                            <StyledLink href="/" color="text.primary">
                                Home
                            </StyledLink>
                            <Box mx={2}>{'>'}</Box>
                            {breadCrumbList.map((crumb, index) => (
                                <>
                                    {(index < breadCrumbListLength - 1 && (
                                        <StyledLink key={crumb.slug + index} href={crumb.slug} color="text.primary">
                                            {crumb.name}
                                        </StyledLink>
                                    )) || <StyledTypography>{crumb.name}</StyledTypography>}

                                    {index < breadCrumbListLength - 1 && <Box mx={2}>{'>'}</Box>}
                                </>
                            ))}
                        </Box>
                    ))}
            </Container>
        </Box>
    );
}

export default Breadcrumbs;
