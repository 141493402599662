import React, { useState, useEffect } from 'react';
import { Box, Button, Link, Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import IconLogo from '../images/icon-logo-mobileplus';
import { BLOCKS, MARKS, Document as ContentfulDocument } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { redirectWithParams } from '../utils/Utils.js';

export interface AdInfo {
    modelName: string;
    brandName: string;
    adBlockDynamicPrice: string;
    adBlockPromoText: ContentfulDocument;
    adBlockDescription: ContentfulDocument;
    isSecondaryBlock: boolean;
    canonicalUrl: string;
}

const StyledContainer = styled(Container)({
    '&.MuiContainer-root': {
        backgroundColor: '#F0F3FF',
        borderRadius: '2px 16px 2px 2px',
        padding: 0,
    },
});

const PricingContainer = styled(Container)({
    '&.MuiContainer-root': {
        backgroundColor: '#6b8bff',
        borderRadius: '2px 16px',
        padding: '10px',
        color: '#FFFFFF',
        mr: '0px',
    },
});

const Bold = ({ children }) => (
    <Typography component="span" fontWeight="bold">
        {children}
    </Typography>
);
const Italic = ({ children }) => (
    <Typography component="span" fontStyle="italic" color="text.secondary">
        {children}
    </Typography>
);
const Text = ({ children }) => <Typography className="">{children}</Typography>;

const options = {
    renderMark: {
        [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
        [MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
};

function AdBlock(adInfo: AdInfo, { location }) {
    const secondary = adInfo.isSecondaryBlock;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <StyledContainer>
            <Grid container sx={{ mb: 0, pb: 2 }} display="flex" justifyContent="center">
                <Grid xs={9} spacing={1} sx={{ mt: '25px', mb: '0px', pl: { xs: 2, md: 4 } }}>
                    <Box
                        variant="div"
                        sx={{ width: { xs: '158px', md: '198px' }, marginBottom: { xs: '5px', md: '15px' } }}
                    >
                        <IconLogo style={{ width: '100%', height: 'auto' }} />
                    </Box>
                    {secondary ? (
                        <Typography sx={{ fontSize: '22px', fontWeight: 'bold' }}>{adInfo.modelName}</Typography>
                    ) : (
                        <h1 style={{ fontSize: '22px', fontWeight: 'bold' }}>{adInfo.modelName}</h1>
                    )}
                </Grid>

                <Grid xs={3} sx={{ pl: '0px', marginLeft: '0px' }}>
                    {/* <PricingContainer
                        sx={{ height: isMobile ? '95px' : '100px', width: isMobile ? '95px' : '100px', mr: '0px' }}
                    >
                        <Grid xs={12} sx={{ marginLeft: '0px', pl: '0px' }}>
                            <Typography sx={{ fontWeight: '600', fontSize: '14px' }}>From</Typography>
                        </Grid>
                        <Grid xs={12} display="flex" justifyContent="center">
                            <Typography sx={{ fontWeight: '600', fontSize: '26px' }}>
                                ${adInfo.adBlockDynamicPrice}
                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography sx={{ fontWeight: '600', fontSize: '14px', float: 'right' }} variant="sup">
                                /mo
                            </Typography>
                        </Grid>
                    </PricingContainer> */}
                </Grid>

                <Grid xs={12} md={12} px={{ xs: 2, md: 4 }} pb={1}>
                    <Box component="p" justifyContent="left">
                        {adInfo.adBlockPromoText !== null && (
                            <Box
                                sx={{
                                    '& > p': {
                                        fontSize: '14px',
                                    },
                                    '& > p > span': {
                                        fontSize: '14px',
                                    },
                                }}
                                mb={1}
                            >
                                {typeof adInfo.adBlockPromoText !== undefined &&
                                    typeof options !== undefined &&
                                    renderRichText(adInfo.adBlockPromoText, options)}
                            </Box>
                        )}
                        {adInfo.adBlockDescription !== null && (
                            <Box
                                sx={{
                                    '& > p': {
                                        fontSize: '16px',
                                    },
                                    '& > p > span': {
                                        fontSize: '16px',
                                    },
                                }}
                                mb={1}
                            >
                                {typeof adInfo.adBlockDescription !== undefined &&
                                    typeof options !== undefined &&
                                    renderRichText(adInfo.adBlockDescription, options)}
                            </Box>
                        )}

                        {/* <Typography
                            sx={{ fontSize: '14px', lineHeight: '19px', display: { xs: 'inline', md: 'block' } }}
                            variant="span"
                            mb={1}
                        >
                            * New Phone & Annual Payment + 20% off using promo code: <b>Save2022</b>
                        </Typography>
                        <Typography
                            sx={{ fontSize: '16px', lineHeight: '19px', display: { xs: 'inline', md: 'block' } }}
                            variant="span"
                        >
                            Check our Lite, Classic and Prestige mobile phone insurance plans with various levels of
                            coverage that can include for screen repair, damage device replacement and loss / theft
                            replacement.
                        </Typography> */}
                        {/* <Typography
                            sx={{ fontSize: '16px', lineHeight: '19px', display: { xs: 'inline', md: 'block' } }}
                            variant="span"
                        >
                            Mobile phone Insurance made simple. We cover repairs, replacements for damage, lost or
                            stolen.
                        </Typography>
                        <Typography
                            sx={{ fontSize: '16px', lineHeight: '19px', display: { xs: 'inline-block', md: 'block' } }}
                            variant="span"
                        >
                            replacements for damage, lost or stolen.
                        </Typography> */}
                    </Box>
                </Grid>

                <Grid xs={12} md={8} display="flex" sx={{ margin: { xs: '0 18px', md: '0' } }} justifyContent="center">
                    {/* <Button
                        component={Link}
                        id="btn-supp-check-pricing"
                        variant="contained"
                        sx={{
                            mb: 2,
                            color: '#ffffff',
                            textTransform: 'none',
                            fontSize: '16px',
                            minHeight: '45px',
                            width: '100%',
                            padding: '5px 10px',
                        }}
                        href={insuranceLink}
                    >
                        <span
                            style={{
                                textAlign: 'center',
                                lineHeight: '1.1',
                                display: 'inline-block',
                                wordBreak: 'break-word',
                            }}
                        >
                            Check{` `}
                            <span>{adInfo.modelName}</span>
                            {` `}Pricing Now
                        </span>
                    </Button> */}
                </Grid>
            </Grid>
        </StyledContainer>
    );
}

export default AdBlock;
